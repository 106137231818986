.title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

@include media-breakpoint-up(lg) {
  .title {
    font-size: 2rem;
  }
}

h4 {
  font-size: 1rem;
}
