ngb-accordion {
  .card-header {
    text-transform: none;
    h4 {
      font-weight: 600;
      font-size: 1.125rem;
      margin-bottom: 0;
      color: #000;
    }
    padding: 0;
  }

  .card-body {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
}
