/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-Italic.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

body {
  font-family: 'Montserrat', Verdana, Arial, sans-serif;
  margin: 0;
  overflow: hidden;
}

@import 'scss/verder';
@import 'scss/custom';
