.sidebar {
  position: absolute;
  bottom: 0;
  flex-direction: column;
  flex-shrink: 0;
  top: 0.5rem;
  left: 0;
  margin-top: env(safe-area-inset-top);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  transform: translateX(calc(-110% - 1px));
  transition: ease-in-out 0.5s;
  z-index: 1000;
}
.sidebar-divider {
  border-top: 1px solid #dedfdf;
  margin: 0 0.75rem;
  height: 4rem;
}
.sidebar-open {
  transform: translateX(0px);
}

.sidebar-logo {
  top: 8px;
  padding: 2rem 1.25rem 0 1.25rem;
  z-index: 1100;
  margin-bottom: 1rem;
}

.navbar-left-controls {
  display: none;
  position: absolute;
  height: 4rem;
  width: 17rem;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: #fff;
}

.navbar-right-controls {
  margin-top: env(safe-area-inset-top);
  position: fixed;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  width: 170px;
  height: 70px;
  right: 0;
  top: 0;
  z-index: 1015;
}

.navbar-right-background {
  position: absolute;
  background-color: #ffffff;
  height: 100%;
  width: 170px;
  right: -60px;
  top: 0;
  box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.161);
  border-bottom: 1px solid rgba(0, 0, 0, 0.161);
  transform: skew(60deg);
}

.navbar-toggle {
  color: #000;
  background: center no-repeat;
  position: relative;
  top: 6px;
  right: -2px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.hamburger-icon {
  top: 25px;
  width: 20px;

  &::after {
    top: 6px;
    width: 30px;
  }
  &::before {
    top: -6px;
    width: 30px;
  }

  &,
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 2px;
    background: #000;
    transition: all 0.5s ease;
  }

  &.active {
    background: transparent;
    transform: rotate(-135deg);
    top: 53%;
    left: 10%;

    &::before {
      transform: rotate(90deg);
      top: 0px;
      left: -6px;
    }
    &::after {
      top: 0px;
      left: -6px;
      width: 30px;
    }
  }
}

.sidebar-closed-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  width: 30px;
  height: 30px;
}
.sidebar-open-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
}

.navbar-left-background {
  position: absolute;
  background-color: #ffffff;
  height: 70px;
  width: 331px;
  left: -80px;
  top: 0;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.161);
  transform: skew(-60deg);
}

.navigation-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  line-height: 0;
}

.navigation-list-item {
  color: #7f7f7f;
  vertical-align: middle;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  padding: 0.5rem;

  cursor: pointer;

  .list-icon {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
    fill: #7f7f7f;

    svg.svg-inline--fa {
      height: 1.25rem;
      margin-right: 1rem;
    }
  }

  &.active {
    color: var(--color-vendor);
    // border-right: 2px solid var(--color-vendor);
    svg {
      fill: var(--color-vendor);
    }
  }
}

.navigation-list-header {
  margin: 0 1rem;
  margin-bottom: 2rem;
  font-weight: 600;
  // text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.profile-entry {
  cursor: default;
}

.lang-list-item {
  @extend .navigation-list-item;
  .list-icon {
    visibility: hidden;
  }

  &.lang-active {
    color: var(--color-vendor);

    .list-icon {
      visibility: visible;
      svg {
        fill: var(--color-vendor);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .sidebar {
    width: auto;
    overflow-x: visible;
  }
}

@include media-breakpoint-up(lg) {
  .sidebar {
    position: relative;
    top: 0;
    bottom: 0;
    transform: none;
    overflow: visible;
    width: 17.5rem;
  }

  .sidebar-logo {
    position: relative;
  }

  .navbar-right-controls {
    display: none;
  }
  .navbar-left-controls {
    display: flex;
  }
  .sidebar-logo {
    padding-top: 8px;
  }
}
