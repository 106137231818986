.icon {
  width: rem(30);
  margin-right: rem(25);
  &::before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-position: center;
  }
}

$icons: (
  action: (
    url: '~/assets/images/vectors/device-action.svg',
    width: 30,
    height: 30,
  ),
  notification: (
    url: '~/assets/images/vectors/notification.svg',
    width: 30,
    height: 30,
  ),
  status: (
    url: '~/assets/images/vectors/status.svg',
    width: 30,
    height: 30,
  ),
  journal: (
    url: '~/assets/images/vectors/journal.svg',
    width: 20,
    height: 20,
  ),
  add: (
    url: '~/assets/images/vectors/add.svg',
    width: 30,
    height: 30,
  ),
  trash: (
    url: '~/assets/images/vectors/trash.svg',
    width: 20,
    height: 20,
  ),
  refresh: (
    url: '~/assets/images/vectors/refresh.svg',
    width: 30,
    height: 30,
  ),
  files: (
    url: '~/assets/images/vectors/files.svg',
    width: 20,
    height: 20,
  ),
  versions: (
    url: '~/assets/images/vectors/versions.svg',
    width: 20,
    height: 20,
  ),
  versions-card: (
    url: '~/assets/images/vectors/versions.svg',
    width: 30,
    height: 30,
  ),
  gears: (
    url: '~/assets/images/vectors/gears.svg',
    width: 30,
    height: 30,
  ),
  license: (
    url: '~/assets/images/vectors/license.svg',
    width: 20,
    height: 20,
  ),
  menu: (
    url: '~/assets/images/vectors/burger-open.svg',
    width: 30,
    height: 30,
  ),
  close: (
    url: '~/assets/images/vectors/burger-close.svg',
    width: 30,
    height: 30,
  ),
  chain: (
    url: '~/assets/images/vectors/chain.svg',
    width: 20,
    height: 20,
  ),
  gears-2: (
    url: '~/assets/images/vectors/gears2.svg',
    width: 20,
    height: 20,
  ),
  leak-check: (
    url: '~/assets/images/vectors/leak-check.svg',
    width: 20,
    height: 20,
  ),
  appearance: (
    url: '~/assets/images/vectors/appearance.svg',
    width: 30,
    height: 30,
  ),
  balance: (
    url: '~/assets/images/vectors/balance.svg',
    width: 30,
    height: 30,
  ),
  auto-cleaner: (
    url: '~/assets/images/vectors/auto-cleaner.svg',
    width: 20,
    height: 20,
  ),
  carrier-gas: (
    url: '~/assets/images/vectors/carrier-gas.svg',
    width: 20,
    height: 20,
  ),
  components: (
    url: '~/assets/images/vectors/components.svg',
    width: 20,
    height: 20,
  ),
  copy: (
    url: '~/assets/images/vectors/copy.svg',
    width: 30,
    height: 30,
  ),
  counter: (
    url: '~/assets/images/vectors/counter.svg',
    width: 30,
    height: 30,
  ),
  diagnosis: (
    url: '~/assets/images/vectors/diagnosis.svg',
    width: 30,
    height: 30,
  ),
  glass: (
    url: '~/assets/images/vectors/glass.svg',
    width: 30,
    height: 30,
  ),
  dot-pile-carbon: (
    url: '~/assets/images/vectors/dot-pile-carbon.svg',
    width: 30,
    height: 30,
  ),
  dot-pile-sulfur: (
    url: '~/assets/images/vectors/dot-pile-sulfur.svg',
    width: 30,
    height: 30,
  ),
  firmware: (
    url: '~/assets/images/vectors/firmware.svg',
    width: 20,
    height: 20,
  ),
  furnace: (
    url: '~/assets/images/vectors/furnace.svg',
    width: 30,
    height: 30,
  ),
  tick: (
    url: '~/assets/images/vectors/tick.svg',
    width: 30,
    height: 30,
  ),
  avatar: (
    url: '~/assets/images/vectors/avatar.svg',
    width: 20,
    height: 20,
  ),
  dashboard: (
    url: '~/assets/images/vectors/dashboard.svg',
    width: 20,
    height: 20,
  ),
  device-list: (
    url: '~/assets/images/vectors/dashboard.svg',
    width: 20,
    height: 20,
  ),
  device-list-sidebar: (
    url: '~/assets/images/vectors/dashboard.svg',
    width: 20,
    height: 20,
  ),
  graph: (
    url: '~/assets/images/vectors/graph.svg',
    width: 20,
    height: 20,
  ),
  graph-2: (
    url: '~/assets/images/vectors/graph-2.svg',
    width: 20,
    height: 20,
  ),
  graph-setting: (
    url: '~/assets/images/vectors/graph-setting.svg',
    width: 20,
    height: 20,
  ),
  analysis-preparation: (
    url: '~/assets/images/vectors/analysis-prepare.svg',
    width: 20,
    height: 20,
  ),
  analysis-report: (
    url: '~/assets/images/vectors/analysis-report.svg',
    width: 20,
    height: 20,
  ),
  dot-pile: (
    url: '~/assets/images/vectors/dot-pile.svg',
    width: 20,
    height: 20,
  ),
  backups: (
    url: '~/assets/images/vectors/backups.svg',
    width: 20,
    height: 20,
  ),
  gauge: (
    url: '~/assets/images/vectors/gauge.svg',
    width: 20,
    height: 20,
  ),
  parameters: (
    url: '~/assets/images/vectors/params.svg',
    width: 20,
    height: 20,
  ),
  profile: (
    url: '~/assets/images/vectors/profile.svg',
    width: 30,
    height: 30,
  ),
  progress: (
    url: '~/assets/images/vectors/progress.svg',
    width: 30,
    height: 30,
  ),
  scale: (
    url: '~/assets/images/vectors/scale.svg',
    width: 30,
    height: 30,
  ),
  units: (
    url: '~/assets/images/vectors/units.svg',
    width: 30,
    height: 30,
  ),
  water-temperature: (
    url: '~/assets/images/vectors/water-temperature.svg',
    width: 30,
    height: 30,
  ),
  library: (
    url: '~/assets/images/vectors/library.svg',
    width: 20,
    height: 20,
  ),
  lens: (
    url: '~/assets/images/vectors/lens.svg',
    width: 20,
    height: 20,
  ),
  lens-dots: (
    url: '~/assets/images/vectors/lens-dots.svg',
    width: 30,
    height: 30,
  ),
  lens-graph: (
    url: '~/assets/images/vectors/lens-graph.svg',
    width: 20,
    height: 20,
  ),
  lims-import: (
    url: '~/assets/images/vectors/lims-import.svg',
    width: 20,
    height: 20,
  ),
  dashboard-twocol: (
    url: '~/assets/images/vectors/dashboard-twocol.svg',
    width: 20,
    height: 20,
  ),
  home: (
    url: '~/assets/images/vectors/home.svg',
    width: 15,
    height: 15,
  ),
  iot-logo: (
    url: '~/assets/images/logo-iot.svg',
    width: 135,
    height: 45,
  ),
  part-vs: (
    url: '~/assets/images/logo-iot.svg',
    width: 180,
    height: 45,
  ),
  checkbox-checked: (
    url: '~/assets/images/vectors/checkbox-checked.svg',
    width: 20,
    height: 20,
  ),
  checkbox-unchecked: (
    url: '~/assets/images/vectors/checkbox-unchecked.svg',
    width: 20,
    height: 20,
  ),
  checkmark: (
    url: '~/assets/images/check-mark.svg',
    width: 75,
    height: 75,
  ),
  hexagon: (
    url: '~/assets/images/hexagon.svg',
    width: 45,
    height: 45,
  ),
  key: (
    url: '~/assets/images/vectors/key.svg',
    width: 20,
    height: 20,
  ),
  trashcan: (
    url: '~/assets/images/vectors/trashcan.svg',
    width: 30,
    height: 30,
  ),
  settings-sliders: (
    url: '~/assets/images/vectors/settings-sliders.svg',
    width: 20,
    height: 20,
  ),
  cogs: (
    url: '~/assets/images/vectors/cogs.svg',
    width: 30,
    height: 30,
  ),
  tenants: (
    url: '~/assets/images/vectors/tenants.svg',
    width: 20,
    height: 20,
  ),
  my-labs: (
    url: '~/assets/images/vectors/lab.svg',
    width: 20,
    height: 20,
  ),
  info-hub: (
    url: '~/assets/images/vectors/information-hub.svg',
    width: 20,
    height: 20,
  ),
  account-settings: (
    url: '~/assets/images/vectors/account-settings.svg',
    width: 20,
    height: 20,
  ),
  iot-admin: (
    url: '~/assets/images/vectors/iot-admin.svg',
    width: 20,
    height: 20,
  ),
  logout: (
    url: '~/assets/images/vectors/logout.svg',
    width: 20,
    height: 20,
  ),
  angles-right: (
    url: '~/assets/images/vectors/angles-right.svg',
    width: 20,
    height: 20,
  ),
  angles-left: (
    url: '~/assets/images/vectors/angles-left.svg',
    width: 20,
    height: 20,
  ),
);

@each $name, $icon-params in $icons {
  .icon-#{$name}::before {
    width: rem(map-get($icon-params, width));
    height: rem(map-get($icon-params, height));
    background: url(map-get($icon-params, url));
    background-size: rem(map-get($icon-params, width)) rem(map-get($icon-params, height));
  }

  .svg-icon-#{$name} {
    width: rem(map-get($icon-params, width));
    height: rem(map-get($icon-params, height));
  }
}

.svg-icon-part-vs {
  fill: #a2a2a2;
}
