// This file overwrites default variables of bootstrap

// Set Boostrap options

$enable-rounded: false;

// colors
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: #005c91;
$secondary: $gray-500; // gray-500

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in our YIQ color contrast function.;
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

//spacer
$spacer: 0.25rem;
$spacers: (
  0: 0,
  point-5: (
    calc($spacer / 2),
  ),
  1: (
    $spacer,
  ),
  2: (
    $spacer * 2,
  ),
  3: (
    $spacer * 3,
  ),
  4: (
    $spacer * 4,
  ),
  5: (
    $spacer * 5,
  ),
  6: (
    $spacer * 6,
  ),
  7: (
    $spacer * 7,
  ),
  8: (
    $spacer * 8,
  ),
  9: (
    $spacer * 9,
  ),
  10: (
    $spacer * 10,
  ),
  11: (
    $spacer * 11,
  ),
  12: (
    $spacer * 12,
  ),
  15: (
    $spacer * 15,
  ),
);
//percentage spaces
$size: 10%;
$sizes: (
  10: (
    $size,
  ),
  20: (
    $size * 2,
  ),
  30: (
    $size * 3,
  ),
  40: (
    $size * 4,
  ),
  50: (
    $size * 5,
  ),
  60: (
    $size * 6,
  ),
  70: (
    $size * 7,
  ),
  80: (
    $size * 8,
  ),
  90: (
    $size * 9,
  ),
);
//body
$body-bg: #e9e9e9;

// modal

// grid

$grid-gutter-width: 1.5rem;
$border-width: 1px;

// font
$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$text-muted: #7f7f7f;
$hr-border-color: #7f7f7f;

$breadcrumb-divider: quote('\f054');

//css variables

$no-vendor-color: #7f7f7f;
$vendor-color: $no-vendor-color;

:root {
  --bg-color-background: #e9e9e9;
  --bg-color-sidebar: #fff;
  --bg-color-card: #fff;
  --color-heading: #000;
  --color-table-value: #7f7f7f;
  --color-device-property: #7f7f7f;
  --color-device-function: #7f7f7f;
  --color-device-function-active: #005c91;
  --color-verder-primary: #005c91;
  --color-verder-primary-light: #{mix(#005c91, #fff, 10%)};
  --color-carbolite-primary: #e30613;
  --color-eltra-primary: #f39200;
  --color-qatm-primary: #ec5300;
  --color-microtrac-primary: #00b4cd;
  --color-retsch-primary: #0072bc;
  --color-success: #4ec633;
  --color-carbolite-primary-light: #fff4e3;
  --color-eltra-primary-light: #fff4e3;
  --color-qatm-primary-light: #{mix(#f39200, #fff, 10%)};
  --color-microtrac-primary-light: #080707;
  --color-retsch-primary-light: #ffede3;
  --color-info: #0072bc;
  --color-modal-success: #32a852;
  --color-warning: #fac415;
  --color-error: #b21422;
  --color-critical: #780650;
  --color-gray-100: $gray-100;
  --color-gray-200: $gray-200;
  --color-gray-300: $gray-300;
  --color-gray-400: $gray-400;
  --color-gray-500: $gray-500;
  --color-gray-600: $gray-600;
  --color-gray-700: $gray-700;
  --color-gray-800: $gray-800;
  --color-gray-900: $gray-900;
  --color-vendor: #{$vendor-color};
  --clr-text: #{$vendor-color};
  --clr-hover-text: #{mix($vendor-color, $white, 70%)};
  --clr-vendor: #{$vendor-color};
  --clr-vendor-wl10: #{mix($vendor-color, $white, 10%)};
  --clr-vendor-wl30: #{mix($vendor-color, $white, 30%)};
  --clr-vendor-wl50: #{mix($vendor-color, $white, 50%)};
  --clr-vendor-wl80: #{mix($vendor-color, $white, 80%)};
  --clr-vendor-d10: #{darken(mix($vendor-color, $white, 10%), 10%)};
  --clr-vendor-d7_5: #{darken(mix($vendor-color, $white, 10%), 7.5%)};
  --clr-focus-text: #{darken(mix($vendor-color, $white, 10%), 7.5%)};
  --clr-shadow: #{rgba(mix(color-yiq($vendor-color), $vendor-color, 15%), 0.5)};
  --clr-vendor-d12_5: #{darken(mix($vendor-color, $white, 10%), 12.5%)};
  --clr-active-text: #{color-yiq(darken(mix($vendor-color, $white, 10%), 12.5%))};
  --clr-outline-shadow: #{rgba($vendor-color, 0.5)};
  --clr-status-idle: rgba(0, 114, 188, 1);
  --clr-status-active: rgb(78, 198, 51);
  --clr-status-waiting: rgb(248, 103, 27);
  --clr-status-initializing: rgb(248, 103, 27);
  --clr-status-offline: #dedede;
  --clr-status-error: rgba(177, 25, 33, 1);
}

$vendors: (
  verder: (
    color: #005c91,
  ),
  carbolite: (
    color: #e30613,
  ),
  eltra: (
    color: #f39200,
  ),
  microtrac: (
    color: #00b4cd,
  ),
  qatm: (
    color: #ec5300,
  ),
  retsch: (
    color: #0072bb,
  ),
);

@each $name, $vendor-params in $vendors {
  $vendor-color: map-get($vendor-params, color);
  .#{$name} {
    --color-vendor: #{$vendor-color};
    --clr-text: #{$vendor-color};
    --clr-hover-text: #{mix($vendor-color, $white, 70%)};
    --clr-vendor: #{$vendor-color};
    --clr-vendor-wl10: #{mix($vendor-color, $white, 10%)};
    --clr-vendor-wl30: #{mix($vendor-color, $white, 30%)};
    --clr-vendor-wl50: #{mix($vendor-color, $white, 50%)};
    --clr-vendor-wl80: #{mix($vendor-color, $white, 80%)};
    --clr-vendor-d10: #{darken(mix($vendor-color, $white, 10%), 10%)};
    --clr-vendor-d7_5: #{darken(mix($vendor-color, $white, 10%), 7.5%)};
    --clr-focus-text: #{darken(mix($vendor-color, $white, 10%), 7.5%)};
    --clr-shadow: #{rgba(mix(color-yiq($vendor-color), $vendor-color, 15%), 0.5)};
    --clr-vendor-d12_5: #{darken(mix($vendor-color, $white, 10%), 12.5%)};
    --clr-active-text: #{color-yiq(darken(mix($vendor-color, $white, 10%), 12.5%))};
    --clr-outline-shadow: #{rgba($vendor-color, 0.5)};
  }
}
