@import '../../node_modules/bootstrap/scss/tables';

.card-table {
  min-height: 1px;
  padding: rem(10) rem(25) 0 rem(25);

  .table-header {
    font-weight: 600;
    display: none;
    border-bottom: 1px solid #dee2e6;
  }

  .row {
    padding-top: rem(15);
    padding-bottom: rem(15);
  }

  .row:not(.table-header) + .row {
    border-top: 1px solid #dee2e6;
  }

  .col-label {
    color: #7f7f7f;
    &::after {
      content: ':';
      margin-right: rem(10);
    }
  }

  @include media-breakpoint-up(sm) {
    .table-header {
      display: flex;
    }

    .col-label {
      display: none;
    }
  }

  @include media-breakpoint-down(xs) {
    .cell {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      margin-bottom: rem(15);

      display: flex;
      justify-content: space-between;
    }
  }

  .row.hover:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
  }
}

.table {
  border-color: transparent;
}

.table thead th {
  border: 0px;
  font-weight: normal;
  padding: rem(15) 0;
}

.table .thead-light th {
  background: none;
  padding-right: rem(15);
}

.table tbody td {
  color: var(--color-table-value);
  padding: rem(15) rem(5);
}
