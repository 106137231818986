// background color workaround while having 2 parallel themes
.app-content::after {
  content: '';
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  height: calc(100% - env(safe-area-inset-top));
  margin-top: env(safe-area-inset-top);
  background-color: var(--bg-color-background);
  z-index: -10;
}

body {
  background-color: #fff;
}

.app-content {
  display: flex;
}

.main-content-wrapper {
  flex-grow: 1;
  display: flex;
  height: calc(100vh - 8px);
  height: calc(100vh - 8px - env(safe-area-inset-top));
  margin-top: 8px;
  margin-top: calc(8px + env(safe-area-inset-top));
  box-sizing: content-box;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-content {
  padding-top: rem(20);
  position: relative;
  flex-grow: 1;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
}

.navbar {
  z-index: 99999;
}

.modal {
  //iOS notch spacing
  margin-top: env(safe-area-inset-top);
  z-index: 100000;
}
.modal-md {
  max-width: 550px;
}

.modal-content-row {
  min-height: 24vh;
}

.rowSelected {
  background-color: lightblue;
}

.modal.show {
  display: block;
}

.invalid-feedback {
  display: block;
}

.device-online {
  background-color: #0aec3580;
}

.device-offline {
  background-color: #ff000080;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.color-success {
  color: #0aec35;
}

.color-failed {
  color: #ff0000;
}

.vertically-scrollable-content {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background:
    /* Shadow Cover TOP */ linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    /* Shadow TOP */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) center top,
    /* Shadow BOTTOM */ radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 48px, 100% 48px, 100% 6px, 100% 6px;
  background-attachment: local, local, scroll, scroll;
}

.custom-control-label {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.unreleased-feature {
  display: none;
}

.hidden {
  visibility: hidden;
}
