@use "sass:map";
@import '../../node_modules/bootstrap/scss/utilities';

$gutter-lg: 1.5rem;
$gutter: 1rem;
$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
);

.large-gutter {
  margin-top: -$gutter;
  margin-left: $gutter * -0.5;
  margin-right: $gutter * -0.5;
  > * {
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 0.5;
    margin-top: $gutter;
  }
}

@include media-breakpoint-up(lg) {
  .large-gutter {
    margin-top: -$gutter-lg;
    margin-left: $gutter-lg * -0.5;
    margin-right: $gutter-lg * -0.5;
    > * {
      padding-right: $gutter-lg * 0.5;
      padding-left: $gutter-lg * 0.5;
      margin-top: $gutter-lg;
    }
  }
}

/**
  Remove gap utilities when updated to Bootstrap v5.0
*/
.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: map.get($spacers, 1);
}

.gap-2 {
  gap: map.get($spacers, 2);
}

.gap-3 {
  gap: map.get($spacers, 3);
}

.gap-4 {
  gap: map.get($spacers, 4);
}

.gap-5 {
  gap: map.get($spacers, 5);
}
