@import '../../node_modules/bootstrap/scss/navbar';

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.vendor-bar {
  margin-top: env(safe-area-inset-top);
  background-color: var(--color-vendor);
  height: 8px;
  width: 100%;
  position: fixed;
  z-index: 5;
  transition: background-color ease-in 0.3s;
}

.no-vendor .vendor-bar {
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    background-color: rgba(233, 236, 239, 0.8);
  }
  50% {
    background-color: rgba(233, 236, 239, 1);
  }
  100% {
    background-color: rgba(233, 236, 239, 0.8);
  }
}

.second-menu {
  display: none;
  position: absolute;
  top: 8px;
  right: 0;

  .dropdown {
    z-index: 4 !important;
  }

  .dropdown-button svg {
    fill: #7f7f7f;
  }
  .dropdown-toggle::after {
    display: none;
  }
}

.second-menu-theme-control {
  padding: 0 !important;

  .custom-checkbox {
    padding: 12px rem(24 + 16);
  }
}

@include media-breakpoint-up(lg) {
  .second-menu {
    display: block;
  }
}
