@import '../../node_modules/bootstrap/scss/utilities/text';
.font-weight-light {
  font-weight: 400 !important;
}
.font-weight-normal {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: 600 !important;
}
.font-weight-bolder {
  font-weight: 700 !important;
}
