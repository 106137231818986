.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  height: rem(50);
  align-items: center;
  font-size: rem(12);
  list-style: none;
  margin-bottom: 0;
}

.breadcrumb-item {
  display: inline;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
  white-space: nowrap;

  a {
    color: #a2a2a2;
  }

  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    padding-left: rem(10);

    &::before {
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-right: rem(10);
      color: rgba(60, 60, 59, 0.5);
      content: escape-svg($breadcrumb-divider);
      font: normal normal normal rem(10) FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  // stylelint-disable-next-line no-duplicate-selectors
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: #a2a2a2;
    text-decoration: none;
  }
}
