.anim-spin {
  animation: spin 4s linear infinite;
}

.anim-blink {
  animation: blink 10s ease-in-out 0s infinite normal forwards;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0.1;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
