.show-validation {
  // combine bootstrap with ng form error state
  input.ng-invalid.ng-touched:not(:focus),
  select.ng-invalid.ng-touched:not(:focus),
  textarea.ng-invalid.ng-touched:not(:focus) {
    @extend .is-invalid;
  }

  input.ng-valid.ng-touched:not(:focus),
  select.ng-valid.ng-touched:not(:focus),
  textarea.ng-valid.ng-touched:not(:focus) {
    @extend .is-valid;
  }
}

$carets: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
.form-control,
.custom-select {
  font-size: rem(14);
  border: none;
  border-bottom: 2px solid rgb(222, 223, 223);
  padding: rem(8.5) 0 rem(8.5) 0;
  height: calc(1.5em + #{rem(17)} + 2px);

  &:disabled {
    color: #a2a2a2;
    background-color: transparent;
  }
}

label + .custom-select {
  padding-top: rem(3.5);
}

.form-control[type='select'],
.custom-select {
  padding: rem(8.5) rem(25) rem(8.5) 0;
  background: transparent $carets right rem(10) center/8px 10px no-repeat;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-select:focus {
  border-color: var(--color-vendor);
}

.form-control:focus {
  box-shadow: none;
}

.input-group-text {
  font-size: rem(14);
  padding: rem(8.5) rem(4) rem(8.5) 0;
  color: #a2a2a2;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(222, 223, 223);
}

.input-group-append .input-group-text {
  padding-left: rem(8.5);
}

label {
  font-size: rem(14);
  margin-bottom: rem(5);
}
