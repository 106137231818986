.list-toolbar {
  margin-top: 0.75rem;
  margin-bottom: 2rem;

  > * {
    margin-top: 0.75rem;
  }
}

@include media-breakpoint-down(xs) {
  .list-toolbar > * {
    width: 100%;
  }
}
