@import 'bootstrap/scss/buttons';

.btn {
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 0.875rem;
  .icon {
    display: inline-block;
    width: 2rem;
    height: 0;
    overflow: visible;
    margin-right: 0;

    &::before {
      content: '';
      top: rem(-19);
      left: 0;
      display: block;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .standalone-icon {
    font-size: 1.125rem;
    color: var(--clr-vendor);
  }
}

.btn-group {
  .btn + .btn {
    margin-left: 2px !important;
  }
}

// vendor color using css variables
.btn-vendor {
  font-weight: 600;
  color: var(--clr-vendor);
  background-color: var(--clr-vendor-wl10);
  border-color: var(--clr-vendor-wl10);

  @include box-shadow($btn-box-shadow);

  @include hover() {
    background-color: var(--clr-vendor-d7_5);
    border-color: var(--clr-vendor-d7_5);
    color: var(--clr-hover-text);
  }

  &:focus,
  &.focus {
    color: var(--clr-vendor);
    border-color: var(--clr-vendor-d7_5);

    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: 0 0 0 $btn-focus-width var(--clr-shadow);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: var(--clr-text);
    background-color: var(--clr-vendor-wl10);
    border-color: var(--clr-vendor-wl10);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: var(--clr-vendor-wl10);
    background-color:var(--clr-vendor);
    border-color:var(--clr-vendor);

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width var(--clr-shadow);
    }
  }
}

.btn-outline-vendor {
  color: var(--clr-vendor);
  border-color: var(--clr-vendor);

  @include hover() {
    color: var(--clr-vendor);
    background-color: var(--clr-vendor-wl10);
    border-color: var(--clr-vendor);
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 $btn-focus-width var(--clr-outline-shadow);
  }

  &.disabled,
  &:disabled {
    color: var(--clr-vendor);
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: var(--clr-active-text);
    background-color: var(--clr-vendor-d10);
    border-color: var(--clr-vendor-d12_5);

    &:focus {
      box-shadow: 0 0 0 $btn-focus-width var(--clr-outline-shadow);
    }
  }
}

.btn-modal-success {
  color: #fff;
  background-color: var(--color-modal-success);
}

.btn-link.grid-card-action-menu {
  font-size: $h4-font-size;
  width: 60px !important;
  height: 60px !important;
}

.btn-link.grid-card-action-menu:hover,
.btn-link.grid-card-action-menu:focus {
  text-decoration: none !important;
  border: 1px dotted var(--clr-outline-shadow) !important;
  box-shadow: none !important;
}

.close {
  font-size: 1.5rem;
  font-weight: normal;
  line-height: 1;
}

.btn-primary-light {
  padding: 0.2rem 0.8rem;
  border-radius: 0.2rem;
  background-color: var(--color-verder-primary-light);
  color: var(--color-verder-primary);
  &:hover {
    .standalone-icon {
      color: var(--color-verder-primary-light);
    }
    background-color: var(--color-verder-primary);
    border-color: var(--color-verder-primary);
  }
}
