.dashboard-column {
  display: flex;
  flex-direction: column;

  & > * {
    flex-grow: 1;
    margin-bottom: 0.875rem;
  }
  & > *:last-child {
    margin-bottom: 0 !important;
  }
}

.full-width {
  flex: 0 0 100% !important;
  max-width: 100% !important;
}

@include media-breakpoint-up(lg) {
  .dashboard-column > * {
    margin-bottom: 1.5rem;
  }
}
