@import '../../node_modules/bootstrap/scss/card';

.card {
  //design content for min-width 470px
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--bg-color-card);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 2px solid var(--color-vendor);
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.161));
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: rem(25);
}

.info-card {
  background-color: var(--bg-color-card);
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.161));
}

.tuple-list {
  flex: 1 1 auto;
  min-height: 1px;
  padding: rem(15) rem(25) 0 rem(25);
}

.tuple-list-item {
  border-top: 1px solid #dedfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:first-of-type {
    border-top-color: transparent;
  }
  min-height: rem(50);
  //Fixes text overflow in Firefox when flexbox is used
  word-wrap: anywhere;
}

.tuple-list-key {
  color: #7f7f7f;
  flex-shrink: 1;
  padding-top: rem(15);
  padding-bottom: rem(15);
  padding-right: rem(5);
}

.tuple-list-value {
  color: #2e2e2d;
  font-size: 1rem;
  text-align: right;
  padding-top: rem(15);
  padding-bottom: rem(15);
  flex-shrink: 1;
}

.card-header {
  display: flex;
  flex-wrap: wrap;
  row-gap: rem(15);
  min-height: 3rem;
  align-items: center;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  box-sizing: content-box;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
}

.card-header .card-title {
  margin-bottom: 0;
}

.card-body .container {
  padding: 0;
}

.card-body hr {
  border: 0;
  border-top: 1px solid #dedfdf;
}

.single-line-no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
